import  { end_points, vcip_end_points } from "./../config";
import DeviceDetector from "device-detector-js";
import axiosLib from 'axios'
const axios = axiosLib.create({
    timeout: 180000
});
let eventBus = window.eventBus;


axios.interceptors.request.use(function (config) {
    eventBus.$emit('vueLoader', true);
    return config;
}, function (error) {
    eventBus.$emit('vueLoader', false);
    return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(function (response) {
    eventBus.$emit('vueLoader', false);
    return response;
}, function (error) {
    console.log('Error occurred during event emit.');
    eventBus.$emit('vueLoader', false);
    return Promise.reject(error);
});

let parseData = async (vue, sessionId) => {
    // let response = await axiosInstance.get(end_points.getCall_SessionDetails(sessionId));
    let response = {};
        if(axiosInstance.defaults.headers.common['Authorization']){
            response = await axiosInstance.get(end_points.getCall_SessionDetails(sessionId));
        }else{
            response = await axiosInstance.get(end_points.getCall_SessionDetails(sessionId), {headers: {'Authorization': sessionId}});
        }
    // let response = await axios.get(vcip_end_points.get_session_by_id(sessionId));
    vue.$store.commit("setUserDetails", response.data.data);
    return response.data.data;
}

let getBrowserData = async () => {
    var userBrowserData = {
        browserName: "",
        cookieEnabled: false,
        browserLanguage: "",
        os: "",
        userAgent: "",
    };
    if (navigator) {
        if (navigator.cookieEnabled && navigator)
            userBrowserData.cookieEnabled = navigator.cookieEnabled.toString();
        if (navigator.language && navigator.language.length > 0)
            userBrowserData.browserLanguage = navigator.language;
        if (navigator.userAgent && navigator.userAgent.length > 0)
            userBrowserData.userAgent = navigator.userAgent;
        if (navigator.plugins && navigator.plugins.length > 0) {
            userBrowserData.pluginsInstalled = [];
            for (var i = 0; i < navigator.plugins.length; i++) {
                userBrowserData.pluginsInstalled.push(navigator.plugins[i].name);
            }
        }
        //not accurate implementation, keeping it for future reference:::::

        // userBrowserData.browserVersion = (function () {
        //     var ua = navigator.userAgent,
        //         tem,
        //         M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
        //     if (/trident/i.test(M[1])) {
        //         tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        //         return 'IE ' + (tem[1] || '');
        //     }
        //     if (M[1] === 'Chrome') {
        //         tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
        //         if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
        //     }
        //     M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
        //     if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
        //     return M.join(' ');
        // })();
    }
    if (screen) {
        if (screen.width && screen)
            userBrowserData.screenWidth = screen.width.toString();
        if (screen.height && screen)
            userBrowserData.screenHeight = screen.height.toString();
        if (screen.pixelDepth && screen)
            userBrowserData.screenPixelDepth = screen.pixelDepth.toString();
        if (screen.colorDepth && screen)
            userBrowserData.screenColorDepth = screen.colorDepth.toString();
    }
    const deviceDetector = new DeviceDetector();
    const userAgent = navigator.userAgent;
    const device = deviceDetector.parse(userAgent);
   
    userBrowserData["deviceInfo"] = {
        "is_mobile": /Android|iPhone|iPad|iPod/i.test(navigator.userAgent),
        "complete_device_name": `${device.device.brand} ${device.device.model}`,
        "form_factor": device.device.type,
        "os": device.os
    }
    
    // if (WURFL)
    //     userBrowserData.deviceInfo = WURFL;
    const isIphone = /iPhone|iPad|iPod/i.test(navigator.userAgent);  
    if((device.device.type && device.device.type.toLowerCase() !='desktop')||isIphone){     
        if(!(device.device.brand && device.device.model)|| isIphone){
        try{
         let userAgentData = (await axios.get(`${vcip_end_points.deviceDetails}?userAgent=${userAgent.toString()}`)).data

         console.log('calling userstack api ')
         console.log(userAgentData.data)
         userBrowserData["deviceInfo"] = {
            "is_mobile": userAgentData.data.device.is_mobile_device ,
            "complete_device_name": `${userAgentData.data.device.brand} ${userAgentData.data.device.name}`,
            "form_factor": userAgentData.data.device.type,
            "os": userAgentData.data.os,
        };
        userBrowserData.browserName = userAgentData.data.browser.name;
        userBrowserData.browserVersion= userAgentData.data.browser.version;
         }
         catch(e){
            console.log(e)
            userBrowserData["deviceInfo"] = {
                "is_mobile": /Android|iPhone|iPad|iPod/i.test(navigator.userAgent),
                "complete_device_name": `${device.device.brand} ${device.device.model}`,
                "form_factor": device.device.type,
                "os": device.os
            }
         }
        }}
        //now using device-detector's OS, browser version and name for better accurary 
        device.os ? userBrowserData.os = device.os.name : userBrowserData.os = navigator.platform;
        if(device.client.name){
            userBrowserData.browserName = device.client.name
        }
        if(device.client.version){
            userBrowserData.browserVersion = device.client.version
        }
    return userBrowserData;
}

let getSessionDetails = async (sessionId) => {
    try {
        let response = await axios.get(vcip_end_points.get_session_details(sessionId));
        //sessionStorage.setItem('meetingName', response.data.meetingName);
        return response.data.meetingName;
    } catch (error) {
        //Throw to the random page!
    }
}

let getSession = async (sessionId) => {
    try {
        let response = {};
        if(axiosInstance.defaults.headers.common['Authorization']){
            response = await axiosInstance.get(end_points.getCall_SessionDetails(sessionId));
        }else{
            response = await axiosInstance.get(end_points.getCall_SessionDetails(sessionId), {headers: {'Authorization': sessionId}});
        }
        // let response = await axios.get(vcip_end_points.get_session_by_id(sessionId));
        
        return response.data.data;
    } catch (error) {
        console.log(error);
        //navigate to random page
    }
}

//For non admin users
let verifySession = async (vue, email, meetingCode, sessionId) => {
    try {
        let response = await axios.post(vcip_end_points.verify_session, {
            "sessionId": sessionId,
            "meetingCode": meetingCode,
            "email": email
        });
        // vue.$store.commit("setUserDetails", response.data.result);
        // store.setData("adminUser", "false");
        // store.setData("sessionId", sessionId);

        return await parseData(vue, sessionId);
        //joinSession(sessionId);

    } catch (error) {
        console.log(error);
        throw new Error("User Invalid");
        //navigate to random page
    }
}

export {
    parseData,
    getSessionDetails,
    getBrowserData,
    getSession,
    verifySession
}